import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Block from '../../components/ui/Cards/Block';
import MauticForm from '../../components/ui/Forms/MauticForm/MauticForm';
import Seo from '../../components/utils/Seo';
import { Direction } from '../../globals';
import './drl.scss';

interface DRLCProps {
    data: {
        file: FluidImage;
    };
}
const DRLC: React.FC<DRLCProps> = (props: DRLCProps) => {
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Deep Reinforcement Learning free course"
                url="deep_reinforcement_learning_course/"
                description="Free ebook focused on Deep Reinforcement Learning"
                image={props.data.file.childImageSharp.fluid.src}
            />
            <PageSection className="drl-course">
                <Container>
                    <Group size={2}>
                        <Block direction={Direction.vertical}>
                            <h1>Deep Reinforcement Learning free course</h1>
                            <h2>
                                Start with Deep Reinforcement Learning. Learn
                                the basic principles and the explore advanced
                                techniques such as Deep Q Networks, Actor
                                critics and Policy Gradients
                            </h2>
                            <MauticForm
                                formId="4"
                                formName="drlebook"
                                isDark={false}
                            />
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="drl-course__image"
                        >
                            <Img
                                fluid={props.data.file.childImageSharp.fluid}
                                alt="Deep Reinforcement Learning free course"
                            ></Img>
                        </Block>
                    </Group>
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export const drlQuery = graphql`
    {
        file(relativePath: { eq: "rl-course.png" }) {
            childImageSharp {
                fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                }
            }
        }
    }
`;

export default DRLC;
